<template>
  <div id="app">
    <header>
      <h1>Bem-vindo ao Amazing Mind</h1>
      <p>Onde aprender é uma aventura incrível!</p>
    </header>
    <nav>
      <router-link to="/">Início</router-link>
      <router-link to="/sobre">Sobre</router-link>
      <router-link to="/webapps">Webapps</router-link>
      <router-link to="/contato">Contato</router-link>
    </nav>
    <router-view />
    <footer>
      <p>&copy; 2024 Amazing Mind. Todos os direitos reservados.</p>
    </footer>
  </div>
</template>

<script>

export default {
  name: 'App',
  components: {
    
  }
}
</script>

<style>

body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  background-color: #f0f8ff;
  color: #333;
}
header {
  background-color: #4CAF50;
  color: white;
  padding: 1rem 0;
  text-align: center;
}
nav {
  display: flex;
  justify-content: center;
  background-color: #333;
}
nav a {
  color: white;
  padding: 1rem;
  text-decoration: none;
  text-align: center;
}
nav a:hover {
  background-color: #575757;
}
.container {
  padding: 2rem;
  text-align: center;
}
footer {
  background-color: #333;
  color: white;
  text-align: center;
  padding: 1rem 0;
  position: fixed;
  width: 100%;
  bottom: 0;
}
</style>
